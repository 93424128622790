<template>
	<details class="group">
		<summary class="flex relative my-2 pr-6 list-none items-start after:absolute after:right-1 after:mt-1 after:ml-2 after:border-r-2 after:border-b-2 after:p-1 after:rotate-45 group-open:after:-rotate-135 group-open:after:mt-2">
			<div class="bg-blue text-white w-6 h-6 shrink-0 flex justify-center items-center rounded-full mr-2">
				<Icon type="information" size="xs"></Icon>
			</div>
			{{ props.question }}
		</summary>
		<p class="mb-2 whitespace-pre-line">
			<slot>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae tincidunt nunc, rutrum dapibus ipsum. Nunc feugiat auctor nunc, in tempor dui porttitor ac. In hac habitasse platea dictumst. Sed pellentesque ipsum est,
				vitae dictum lectus bibendum commodo. Sed eget erat quam. Fusce pellentesque tristique pharetra. Nam mattis scelerisque tortor, eu malesuada dolor elementum a. Vivamus fringilla augue purus, in efficitur eros fringilla
				ultrices. Ut pretium dui id mi cursus, sit amet vestibulum tellus maximus. Phasellus sodales pharetra arcu, nec mollis felis dictum ut. Duis sagittis pulvinar tempor. Morbi ut pretium enim, dictum porttitor lectus.
				Pellentesque vel augue id lorem pretium ullamcorper. Suspendisse ultricies, ante id rutrum ornare, augue sapien tristique ex, at tincidunt magna magna quis justo. Aliquam gravida lacinia urna eget egestas.
			</slot>
		</p>
	</details>
</template>

<script setup lang="ts">
	const props = defineProps({
		question: { type: String },
	});
</script>

<style scoped>
	details summary::-webkit-details-marker {
		display: none;
	}
</style>
