import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

import { useGlobal } from '@/store/global';
	import { useToggleMenu } from '@/store/toggleGlobalMenu';
	import { computed } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'MobileMenu',
  setup(__props) {

	const toggleMenu = useToggleMenu();
	const global = useGlobal();

	const classObject = computed(() => ({
		'h-24 w-24 dark:bg-ph-background-5 bg-ph-background-4': toggleMenu.globalIsActive,
		'h-16 w-16': !toggleMenu.globalIsActive && !global.isModalVisible,
	}));

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (!(_ctx.$route.name === 'onboarding'))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([classObject.value, "centered absolute top-0 left-0"]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(toggleMenu).toggleMenuAndSendToHub()))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["centered", { 'w-14 h-14 bg-white hover:bg-lightgray-light rounded-xl': _unref(toggleMenu).globalIsActive }])
        }, [
          (_unref(toggleMenu).globalIsActive)
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 0,
                size: "lg",
                type: "arrow",
                class: "stroke-0 text-black"
              }))
            : (!_unref(toggleMenu).globalIsActive && !_unref(global).isModalVisible)
              ? (_openBlock(), _createBlock(_component_Icon, {
                  key: 1,
                  type: "hamburger",
                  class: "stroke-0 text-ph-text"
                }))
              : _createCommentVNode("", true)
        ], 2)
      ], 2))
    : _createCommentVNode("", true)
}
}

})