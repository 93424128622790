import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute h-full w-24 z-10 bg-gray-middle opacity-75 top-0"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Modal',
  props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
  setup(__props) {

	const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.show)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 64))
}
}

})