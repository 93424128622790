import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "h-full text-black dark:bg-gray-darker dark:text-white" }
const _hoisted_2 = { class: "flex h-full" }

import { HubList, useDialog, useGlobal, useHubs, useSettings } from '@/store/store';
	import { onMounted, watchEffect } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { SMI } from '../../../hub-client/src/dev/StatusMessage';
	import { Logger } from '@/../../hub-client/src/foundation/Logger';
	import MobileMenu from '@/components/ui/MobileMenu.vue';
	import { CONFIG } from '../../../hub-client/src/foundation/Config';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

	const LOGGER = new Logger('GC', CONFIG);

	const global = useGlobal();
	const settings = useSettings();
	const hubs = useHubs();
	const dialog = useDialog();
	const { locale, availableLocales } = useI18n();

	onMounted(async () => {
		LOGGER.log(SMI.STARTUP_TRACE, 'App.vue onMounted...');

		settings.initI18b({ locale: locale, availableLocales: availableLocales });
		dialog.asGlobal();

		// Change active language to the user's preferred language
		locale.value = settings.getActiveLanguage;

		// set language when changed
		settings.$subscribe(() => {
			locale.value = settings.getActiveLanguage;
		});

		if (await global.checkLoginAndSettings()) {
			// Watch for saved state changes and save to backend.
			watchEffect(() => global.saveGlobalSettings());
		}
		await addHubs();

		LOGGER.log(SMI.STARTUP_TRACE, 'App.vue onMounted done', { language: settings.getActiveLanguage });
	});

	async function addHubs() {
		const hubsResponse: HubList | undefined = await global.getHubs();
		if (hubsResponse) {
			hubs.addHubs(hubsResponse as HubList);
		}
	}

return (_ctx: any,_cache: any) => {
  const _component_GlobalBar = _resolveComponent("GlobalBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_unref(settings).getActiveTheme, "h-full"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(MobileMenu),
      _createElementVNode("div", _hoisted_2, [
        (!(_ctx.$route.name === 'onboarding'))
          ? (_openBlock(), _createBlock(_component_GlobalBar, { key: 0 }))
          : _createCommentVNode("", true),
        (_unref(hubs).hasHubs)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["flex-1 dark:bg-gray-dark", { 'overflow-y-auto': _ctx.$route.name !== 'onboarding' }])
            }, [
              _createVNode(_component_router_view)
            ], 2))
          : _createCommentVNode("", true)
      ])
    ]),
    (_unref(dialog).visible)
      ? (_openBlock(), _createBlock(_component_Dialog, {
          key: 0,
          onClose: _unref(dialog).close
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})